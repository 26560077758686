<div class="widget">
    <div class="title">Building Health</div>

    <div class="widget__content">
        @if(isLoading()) {
            <qpx-loader></qpx-loader>
        } @else {
            @if(categories.length) {
                <div class="top-section">
                    @if(categories[0]) {
                        <div class="widget-item"
                            [ngClass]="{'link': canNavigateToMaintenance(), 'non-compliant': categories[0].nonCompliantCount}"
                            (click)="navigateToEsm(categories[0].esmCategory)">
                            <div class="widget-item__text">
                                <span class="label">
                                    {{getCategoryLabel(categories[0].esmCategory)}}
                                </span>
        
                                @if(responseIsEmpty()) {
                                    <span class="no-data">No data</span>
                                } @else {
                                    @if(categories[0].nonCompliantCount){
                                        <span>{{categories[0].nonCompliantCount}} not compliant</span>
                                    } @else {
                                        <span>Compliant</span>
                                    }
                                }
                            </div>
        
                            @if(!responseIsEmpty()) {
                                @if(categories[0].nonCompliantCount){
                                    <mat-icon class="warning">error</mat-icon>
                                } @else {
                                    <mat-icon>check_circle</mat-icon>
                                }
                            }
                        </div>
                    }
    
                    @if(categories[1]) {
                        <div class="widget-item"
                            [ngClass]="{'link': canNavigateToMaintenance(), 'non-compliant': categories[1].nonCompliantCount}"
                            (click)="navigateToEsm(categories[1].esmCategory)">
                            <div class="widget-item__text">
                                <span class="label">
                                    {{getCategoryLabel(categories[1].esmCategory)}}
                                </span>
        
                                @if(responseIsEmpty()) {
                                    <span class="no-data">No data</span>
                                } @else {
                                    @if(categories[1].nonCompliantCount){
                                        <span>{{categories[1].nonCompliantCount}} not compliant</span>
                                    } @else {
                                        <span>Compliant</span>
                                    }
                                }
                            </div>
                            @if(!responseIsEmpty()) {
                                @if(getCategoryLabel(categories[1].nonCompliantCount)){
                                    <mat-icon class="warning">error</mat-icon>
                                } @else {
                                    <mat-icon>check_circle</mat-icon>
                                }
                            }
                        </div>
                    }
                </div>
    
                <div class="bottom-section">
                    @if(categories[2]) {
                        <div class="widget-item"
                            [ngClass]="{'link': canNavigateToMaintenance(), 'non-compliant': categories[2].nonCompliantCount}"
                            (click)="navigateToEsm(categories[2].esmCategory)">
                            <div class="widget-item__text">
                                <span class="label">
                                    {{getCategoryLabel(categories[2].esmCategory)}}
                                </span>
        
                                @if(responseIsEmpty()) {
                                    <span class="no-data">No data</span>
                                } @else {
                                    @if(categories[2].nonCompliantCount){
                                        <span>{{categories[2].nonCompliantCount}} not compliant</span>
                                    } @else {
                                        <span>Compliant</span>
                                    }
                                }
                            </div>
        
                            @if(!responseIsEmpty()) {
                                @if(categories[2].nonCompliantCount){
                                    <mat-icon class="warning">error</mat-icon>
                                } @else {
                                    <mat-icon>check_circle</mat-icon>
                                }
                            }
                        </div>
                    }
    
                    @if(categories[3]) {
                        <div class="widget-item"
                            [ngClass]="{'link': canNavigateToMaintenance(), 'non-compliant': categories[3].nonCompliantCount}"
                            (click)="navigateToEsm(categories[3].esmCategory)">
                                <div class="widget-item__text">
                                    <span class="label">
                                        {{getCategoryLabel(categories[3].esmCategory)}}
                                    </span>
            
                                    @if(responseIsEmpty()) {
                                        <span class="no-data">No data</span>
                                    } @else {
                                        @if(categories[3].nonCompliantCount){
                                            <span>{{categories[3].nonCompliantCount}} not compliant</span>
                                        } @else {
                                            <span>Compliant</span>
                                        }
                                    }
                                </div>

                                @if(!responseIsEmpty()) {
                                    @if(categories[3].nonCompliantCount){
                                        <mat-icon class="warning">error</mat-icon>
                                    } @else {
                                        <mat-icon>check_circle</mat-icon>
                                    }
                                }
                        </div>
                    } @else {
                        <div class="widget-item empty"></div>
                    }
    
                </div>
            } @else {
                <ng-container *ngTemplateOutlet="noDataMessage"></ng-container>
            }
        }
    </div>

</div>

<ng-template #noDataMessage>
    <div class="no-data-message">No data available.</div>
</ng-template>
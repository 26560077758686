/* eslint-disable @angular-eslint/no-output-rename */
import { CommonModule } from '@angular/common';
import {
    Component,
    Input,
    OnInit,
    ViewChild,
    output,
    signal
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { FilterModel, FilterModelSelected } from '@quipex/shared/data';

@Component({
    selector: 'qpx-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatSelectModule
    ],
})
export class FilterComponent implements OnInit {
    @Input() filterItem!: FilterModel;
    @Input() counterWithPlaceholder = false;

    filterToRestore!: string | null;

    @Input() set filterToRestoreInput(value: string|null){
        this.filterToRestore = value;
        this.initializeFilteredItems();
    };
    
    @Input() showHeader: boolean = true;
    @Input() inputClasses!: string[];
    @Input() selectPanelClasses!: string[];
    @Input() placeholder: string = 'Select';
    
    adjustedPlaceholder = signal('');
    filterSelected = output<FilterModelSelected[]>();

    @ViewChild('matSelect') matSelect?: MatSelect;
    @ViewChild('allSelectedMatOption') allSelectedMatOption?: MatOption;

    filterItemsCtrl = new FormControl([]);
    appliedFilterItemsCtrl = new FormControl([]);

    allSelected: boolean = false;

    ngOnInit(): void {
        this.adjustedPlaceholder = signal(this.placeholder);
        this.initializeFilteredItems();
    }

    initializeFilteredItems() {
        if (this.filterToRestore && this.filterItem) {
            const filterArray = this.filterItem.array;
            if (Array.isArray(filterArray)) {
                const valueToSelect = (filterArray as Array<any>).find((arrItem) => arrItem.name === this.filterToRestore);
                if (valueToSelect) {
                    this.filterItemsCtrl.setValue([valueToSelect] as any);
                    this.appliedFilterItemsCtrl.setValue([valueToSelect] as any);
                    this.setPlaceholder();
                }
            }
        }
    }

    onToggleAll(): void {
        this.allSelected = !this.allSelected;

        if (this.allSelected) {
            this.matSelect?.options.forEach((item: MatOption) => item.select());
        } else {
            this.matSelect?.options.forEach((item: MatOption) => { item.deselect() });
        }
    }

    onOpenedChanged(open: boolean): void {
        if (!open) {
            const appliedFilters = this.appliedFilterItemsCtrl.value ? [...this.appliedFilterItemsCtrl.value] : [];
            this.filterItemsCtrl.setValue(appliedFilters);
        }
    }

    toggleSingle(): void {
        if (this.allSelectedMatOption?.selected) {
            this.allSelectedMatOption.deselect();
            this.allSelected = false;
        }

        if (this.filterItemsCtrl.value?.length === this.filterItem.array.length) {
            this.allSelectedMatOption?.select();
        }
    }

    clearAll(): void {
        this.filterItemsCtrl.setValue([]);
    }

    applyFilters(): void {
        const selectedItems = this.filterItemsCtrl.value ? [...this.filterItemsCtrl.value] : [];
        this.appliedFilterItemsCtrl.setValue([...selectedItems]);
        this.setPlaceholder();
        this.filterSelected.emit(selectedItems);
        this.matSelect?.close();
    }

    getTotalSelectedLabel(): string {
        const totalSelected = this.filterItemsCtrl?.value?.length;

        if (this.allSelected) {
            return `${(totalSelected ? totalSelected - 1 : 0)} Selected`;
        } else {
            return `${(totalSelected ? totalSelected : 0)} Selected`;
        }
    }

    getTotalSelectedCounter(): string {
        const totalSelected = this.filterItemsCtrl?.value?.length;

        if (this.allSelected) {
            return `${(totalSelected ? totalSelected - 1 : 0)}`;
        } else {
            return `${(totalSelected ? totalSelected : 0)}`;
        }
    }

    private setPlaceholder(): void {
        if (!this.appliedFilterItemsCtrl.value?.length) {
            this.adjustedPlaceholder.set(this.placeholder);
        } else {
            let newPlaceholder = '';
            const totalItems = this.appliedFilterItemsCtrl.value?.length;
            this.appliedFilterItemsCtrl?.value?.forEach((item, index) => {
                if (item['id'] !== 0) {
                    newPlaceholder += item['name'];
                    if (index < totalItems - 1) {
                        newPlaceholder += ', '
                    }
                }
            });
            this.adjustedPlaceholder.set(newPlaceholder);
        }
    }

}

import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { ApiServiceBase } from '../api-service-base';
import {
    IAddTaskPostModel, ICompleteTaskPostModel, IGetTaskListResponse, IGetTaskResponse, ITaskAssignedBuilding, ITaskAssignedOrganisation,
    ITaskAssignedUser, IUpdateAssignedOrg, IUpdateAssignedUser,
    IUpdateDefectPostModel
} from '../models/typings/tasks.interface';

@Injectable({
    providedIn: 'root',
})
export class TasksApiService extends ApiServiceBase {
    private _apiBase = `${this.appConfig.apiBase}/api/tasks`;

    getTasks(payload: any): Observable<IGetTaskListResponse> {
        const url = `${this._apiBase}/list`;
        return this.http.post<any>(url, payload)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getTaskById(id: string): Observable<IGetTaskResponse> {
        const url = `${this._apiBase}/${id}`;
        return this.http.get<any>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getAssignedBuildings(): Observable<ITaskAssignedBuilding[]> {
        const url = `${this._apiBase}/assigned-buildings`;
        return this.http.get<any>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getAssignedOrganizations(): Observable<ITaskAssignedOrganisation[]> {
        const url = `${this._apiBase}/assigned-organizations`;
        return this.http.get<any>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    getAssignedUsers(): Observable<ITaskAssignedUser[]> {
        const url = `${this._apiBase}/assigned-users`;
        return this.http.get<any>(url)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    updateAssignedOrg(model: IUpdateAssignedOrg): Observable<boolean> {
        const url = `${this._apiBase}/assign-org`;
        return this.http.put<boolean>(url, model)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    updateAssignedUser(model: IUpdateAssignedUser): Observable<boolean> {
        const url = `${this._apiBase}/assign-user`;
        return this.http.put<boolean>(url, model)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    addTask(model: IAddTaskPostModel): Observable<any> {
        const url = `${this._apiBase}`;
        return this.http.post<boolean>(url, model)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    updateDefect(model: IUpdateDefectPostModel): Observable<any> {
        const url = `${this._apiBase}/defect`;

        const formData = new FormData();
        formData.append('taskId', model.taskId.toString() ?? '');
        formData.append('status', model.status.toString() ?? '');
        formData.append('completionDate', model.completionDate?.toISOString()?? '');//new Date().toLocaleTimeString() ?? '');
        formData.append('assignedOrgId', model.assignedOrgId?.toString() ?? '');
        formData.append('assignedUserId', model.assignedUserId?.toString() ?? '');

        this.appendDocuments(formData, model);

        model.addedNotes?.forEach((note, index) => {
            formData.append(`addedNotes[${index}]`, note);
        });

        return this.http.put<boolean>(url, formData)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    private appendDocuments(formData: FormData, model: IUpdateDefectPostModel): void {
        if (model.documents?.length) {
            model.documents.forEach((file) => {
                formData.append('evidences', file);
            });
        }
    }

    completeTask(model: ICompleteTaskPostModel): Observable<any> {
        const url = `${this._apiBase}/complete`;

        const formData = new FormData();
        formData.append('taskId', model.taskId.toString() ?? '');
        formData.append('status', model.status.toString() ?? '');
        formData.append('completionDate', model.completionDate?.toString() ?? '');
        formData.append('assignedOrgId', model.assignedOrgId?.toString() ?? '');
        formData.append('assignedUserId', model.assignedUserId?.toString() ?? '');

        model.addedNotes?.forEach((note, index) => {
            formData.append(`addedNotes[${index}]`, note);
        });

        model.documents?.forEach((document, index) => {
            formData.append(`evidences[${index}]`, document);
        });

        return this.http.put<boolean>(url, formData)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

} 
